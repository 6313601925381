export class ServiceConfig {
    /**
     * Development
     */
    // public static readonly API_ENDPOINT = 'http://localhost:52332';
    // public static readonly LOGIN_ENDPOINT = 'http://localhost:52332/Auth';
    // public static readonly SOCKET_IO_ENDPOINT = 'http://localhost:5000';

    public static readonly API_ENDPOINT = 'https://api-dev.foodra.app';
    public static readonly LOGIN_ENDPOINT = 'https://api-dev.foodra.app/Auth';
    public static readonly SOCKET_IO_ENDPOINT =
        'https://api-dev.foodra.app:5010';

    public static readonly STATIC_CONTENT_ENDPOINT =
        'https://fooderia-dev.s3-sa-east-1.amazonaws.com/{company}/{content}';
    public static readonly PRODUCT_IMAGE_ENDPOINT =
        'https://fooderia-dev.s3-sa-east-1.amazonaws.com/{company}/product/{image}';

    public static readonly HELP_GUIDE_URL =
        'https://pitch.com/embed/4fd1cd90-b2c8-41bd-9a6d-35dc1acd83b3';

    /**
     * Production
     */
    // public static readonly API_ENDPOINT = 'https://api.foodra.app';
    // public static readonly LOGIN_ENDPOINT = 'https://api.foodra.app/Auth';
    // public static readonly SOCKET_IO_ENDPOINT = 'https://api.foodra.app:5000';

    // public static readonly STATIC_CONTENT_ENDPOINT =
    //     'https://d1eh9wgn6nna9l.cloudfront.net/{company}/{content}';
    // public static readonly PRODUCT_IMAGE_ENDPOINT =
    //     'https://d1eh9wgn6nna9l.cloudfront.net/{company}/product/{image}';

    // public static readonly HELP_GUIDE_URL =
    //     'https://pitch.com/embed/4fd1cd90-b2c8-41bd-9a6d-35dc1acd83b3';

    public static readonly RECAPTCHA_KEY =
        '6Le_Y4YUAAAAAM3B5mTNn8X1trDzVmLmXdf7JYDb';
}
